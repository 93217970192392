@tailwind base;
@tailwind components;
@tailwind utilities;

// @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,600;;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

// body {
//     margin: 0;
//     /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
//       'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
//       sans-serif; */
//     //   font-family: 'Roboto', sans-serif;
//     -webkit-font-smoothing: antialiased;
//     -moz-osx-font-smoothing: grayscale;
//   }

* {
  font-family: 'Roboto';
}
  
  body {
    font-family: 'Roboto';
    font-size: 16px;
    line-height: 24px;
    margin: 0;
    /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif; */
    //   font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

// html {
//     width: 100%;
// }

.ant-select-single .ant-select-selector {
  border-radius: 0px;
  border: 1px solid #D4D4D4 !important;
}

.ant-tabs-tab {
  color: #000 !important;
  opacity: 50%;
  font-weight: 700;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  font-weight: 700;
}

.total-screen {
  @media screen and (max-width: 1280px) {
    width: fit-content
  }
}

#root {
  height: 100%;
  // overflow: scroll;
}